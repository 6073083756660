import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { Jwt } from '../../shared/models/Jwt.model';
import { User } from '../../features/users/models/User.model';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {
  }

  getJwtToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  saveJwtToken(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  destroyJwtToken() {
    localStorage.removeItem(TOKEN_KEY);
  }

  getDecodedJwtToken() {
    return jwtDecode<Jwt>(this.getJwtToken()!);
  }

  getUser(): User | null {
    const decodedToken = this.getDecodedJwtToken();
    return {
      id: decodedToken.id,
      email: decodedToken.email,
      firstName: decodedToken.fullName.split(' ')[0],
      lastName: decodedToken.fullName.split(' ')[1],
      isAdmin: decodedToken.isAdmin,
    } as User;
  }

  getUserRoles() {
    return this.getDecodedJwtToken().roles;
  }

  getCurrentRestaurantRole() {
    const decodedToken = this.getDecodedJwtToken();
    return decodedToken.roles.find((r) => r.restaurantId === decodedToken.restaurantId);
  }

  initTokens(token: string) {
    this.saveJwtToken(token);
  }
}
