import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERole } from '../../shared/models/ERole.model';
import { TokenService } from './token.service';
import { Restaurant } from '../../features/restaurants/models/Restaurant.model';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  constructor(private router: Router, private tokenService: TokenService) {
  }

  prepareSignIn(token: string) {
    this.tokenService.initTokens(token);
  }

  public getLoggedUser() {
    return this.tokenService.getUser();
  }

  public getUserRoles() {
    return this.tokenService.getUserRoles();
  }

  public getSelectedRestaurant() {
    const role = this.tokenService.getCurrentRestaurantRole();
    return {
      id: role?.restaurantId,
      name: role?.restaurantName,
    } as Restaurant;
  }

  public getUserRole() {
    const role = this.tokenService.getCurrentRestaurantRole();
    if (role) {
      return role.role;
    } else if (this.getLoggedUser()?.isAdmin) {
      return ERole.ADMIN;
    } else {
      return ERole.CLIENT;
    }
  }

  public canBeAdmin() {
    return this.getLoggedUser()!.isAdmin;
  }

  public isLoggedIn() {
    return this.tokenService.getJwtToken() !== null;
  }

  public signOut() {
    this.tokenService.destroyJwtToken();
    this.router.navigate(['auth']);
  }

  public isClient() {
    return this.getUserRole() === ERole.CLIENT;
  }

  public isWorker() {
    return this.getUserRole() === ERole.WORKER;
  }

  isManager() {
    return this.getUserRole() === ERole.MANAGER;
  }

  isAdmin() {
    return this.getUserRole() === ERole.ADMIN;
  }
}
