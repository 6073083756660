import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  supportedLanguages = ['en', 'fr', 'es'];
  defaultLanguage = 'en';
  userLang: string;

  constructor(private translate: TranslateService) {
    this.userLang = translate.getBrowserLang() || this.defaultLanguage;
    translate.addLangs(this.supportedLanguages);
    translate.setDefaultLang(this.defaultLanguage);
  }

  public init() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.changeLanguage(this.supportedLanguages.includes(lang) ? lang : this.defaultLanguage);
    } else {
      this.changeLanguage(this.supportedLanguages.includes(this.userLang) ? this.userLang : this.defaultLanguage);
    }
  }

  public getLanguages() {
    return this.translate.getLangs();
  }

  public getCurrentLanguage() {
    return this.translate.currentLang;
  }

  public changeLanguage(lang: string) {
    lang = this.supportedLanguages.includes(lang) ? lang : this.defaultLanguage;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  public getLanguageLabel(lang: string) {
    switch (lang) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      case 'fr':
        return 'Français';
      default:
        return lang;
    }
  }
}
