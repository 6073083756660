import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading$ = new ReplaySubject<boolean>(1);
  constructor() {}

  getLoadingStatus(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  setLoadingStatus(inprogess: boolean) {
    this.loading$.next(inprogess);
  }
}
